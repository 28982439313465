import { NavLink, Outlet } from "react-router-dom";
import { Accordion, Button } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { encryptLocalStorage } from "../config/utils/storage";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "../components/common/CustomModal";

const AdminHeader: React.FC = () => {
    const [userName, setUserName] = useState("");
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const navigate = useNavigate();

    const addActiveClass = (prop: any) => {

        return prop.isActive ? "text-prmiary active" : "text-dark"
    }

    const handleLogout = () => {
        setShowLogoutConfirmation(true);
    };
    const confirmLogout = () => {
        localStorage.clear();
        setShowLogoutConfirmation(false);
        // window.location.href = "/admin/sign-in/"
        // window.location.replace("/admin/sign-in/");
        navigate("/admin/sign-in/")
    };

    const cancelLogout = () => {
        setShowLogoutConfirmation(false);
    };

    return (
        <>
            <ul
                className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
                id="accordionSidebar"
            >
                <Link className="sidebar-brand" to="/">
                    <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/pvsLogo.svg`} width="100%" alt="logo"></img>
                </Link>
                <hr className="sidebar-divider my-0" />
                <Accordion className="p-3" flush defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3>Vehicle</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/make" className={addActiveClass}>Makes</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/model" className={addActiveClass}>Models</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/variants" className={addActiveClass}>Variants</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/vehicles" className={addActiveClass} >Vehicles</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/fuel" className={addActiveClass}>Fuel</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/transmission" className={addActiveClass}>Transmission</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/body-type" className={addActiveClass}>Body types</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/colours" className={addActiveClass}>Colours</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/specification" className={addActiveClass}>Specifications</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/features" className={addActiveClass}>Features</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/inspections" className={addActiveClass}>Inspections</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/report" className={addActiveClass}>Reports</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/test-drives" className={addActiveClass}>Test Drives</NavLink></p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3>Clients</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/clients" className={addActiveClass}>List</NavLink></p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3>Locations</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/locations" className={addActiveClass}>List</NavLink></p>
                        </Accordion.Body>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/dealerships" className={addActiveClass}>Dealerships</NavLink></p>
                        </Accordion.Body>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/outlets" className={addActiveClass}>Outlets</NavLink></p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h3>Enquiries</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/all-enquiries" className={addActiveClass}>List</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/loan-application" className={addActiveClass}>Loan application</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/refer-and-earn" className={addActiveClass}>Refer and Earn</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/SellCar-enquiries" className={addActiveClass}>Sell car enquiries</NavLink></p>

                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><h3>CMS</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/cms" className={addActiveClass}>CMS List</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/blog" className={addActiveClass}>Blog</NavLink></p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><h3>Testimonial</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/testimonial" className={addActiveClass}>Testimonial</NavLink></p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><h3>Bookings</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/bookings" className={addActiveClass}>Bookings</NavLink></p>
                            <p className="mb-0"><NavLink to="/admin/transactions" className={addActiveClass}>Transactions</NavLink></p>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header><h3>Configuration</h3></Accordion.Header>
                        <Accordion.Body className="p-1">
                            <p className="mb-0"><NavLink to="/admin/bookingConfig" className={addActiveClass}>Booking Amount</NavLink></p>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
                {/* <div className="text-dark p-3"><h3>
                    {userName}
                </h3>
                    <img
                        className="img-profile rounded-circle"
                        src=""
                    /></div> */}
                <h3 className="text-primary p-3" style={{ cursor: 'pointer' }} onClick={handleLogout}>Logout  <FontAwesomeIcon icon={faSignOut} /></h3>
            </ul >
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                    </nav>
                    <div className="mt-2">
                        <Outlet />
                    </div>

                </div>
            </div>
            <CustomModal isOpen={showLogoutConfirmation} title="Logout"
                component={<p>Are you sure you want to continue?</p>}
                closeModal={setShowLogoutConfirmation} buttonList={<>
                    <Button variant="secondary" onClick={cancelLogout}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={confirmLogout}>
                        Logout
                    </Button></>} />
        </>

    );
};

export default AdminHeader;
