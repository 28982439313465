// import { useEffect, useState } from "react";
// import { Card, Image } from "react-bootstrap";

// const VehicleMainImage = ({ image, badge, isDealOfTheDay, isAssuredStatus }: { image: string, badge: string, isDealOfTheDay?: number, isAssuredStatus?:number }) => {

//     const [isMobile, setIsMobile] = useState(false)

//     useEffect(() => {
//         setIsMobile(window.innerWidth <= 565);
//     }, []);

    // const badgeList: any = {
    //     // assured: 'assured_badge',
    //     sold: 'sold_orange_badge',
    //     booked: 'booked_blue_badge'
    // }
//     const badgeUrl = process.env.REACT_APP_CLOUDFRONT_URL + `/public/${badgeList[badge]}.png`
//     return (
//         <>
//             <div className="ribbons-wrapper">
//                 <div className="ribbon">
//                     {isDealOfTheDay == 1 && <span className="ribbon1"><span className="deal">Deal of <br /> the day</span></span>}

//                 </div>
//             </div>

            
//           <div className="container px-0" style={{ position: 'relative', display: 'inline-block' }}>
//                 <Image src={image} width={"100%"} className="mb-3" />
//                 <div className={isMobile ? "details-sale-badge-mobile" : "details-sale-badge"}>
//                     {badge !== "" && <Image src={badgeUrl} width={isMobile ? "100%" : "50%"} />}
//                 </div>
//             </div>
        
            
//             <div className="">
//                 <div className={isMobile ? "details-assured-badge-mobile" : "details-assured-badge"}>
//                     {isAssuredStatus == 1 &&  <Image src={process.env.REACT_APP_CLOUDFRONT_URL + `/public/assured_badge.png`} width={isMobile ? "100%" : "50%"} />}
//                 </div>
//             </div>
               
         
//         </>
//     )
// }
// export default VehicleMainImage


// import React, { useState, useEffect } from "react";
// import { Carousel, Image } from "react-bootstrap";
// import HTTP from "../../config/axios";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
// import { getOriginalUrlFromThumb } from "../../config/utils/helper";

// const VehicleMainImage: React.FC<any> = ({ id, badge, isDealOfTheDay, isAssuredStatus, image, setSelectedMainImage }) => {
//     const [carImagesArray, setCarImagesArray] = useState<any>([]);
//     const [index, setIndex] = useState(0);
//     const [screenType, setScreenType] = useState("desktop");
//     const [ismainImage, setMainImage] = useState("");
//     const [isMobile, setIsMobile] = useState(false); 
//     const [isTablet, setIsTablet] = useState(false); 
//     const [isBigMobile, setIsBigMobile] = useState(false); 


//     useEffect(() => {
//         setIsMobile(window.innerWidth <= 700);
//         setIsTablet(window.innerWidth > 700 && window.innerWidth <= 1200);
//     }, []);



//     useEffect(() => {
//         const getCarImages = async () => {
//             if (!id) return;

//             try {
//                 const response = await HTTP.get(`/api/vehicle/thumbnail-images/${id}`);
//                 const { data: { status, data } } = response;

//                 if (status === 1 && Array.isArray(data)) {
//                     setCarImagesArray(data);
//                 } else {
//                     setCarImagesArray([]);
//                 }
//             } catch (error) {
//                 console.error("Error fetching images", error);
//                 setCarImagesArray([]);
//             }
//         };

//         getCarImages();
//     }, [id]);

//     useEffect(() => {
//         setMainImage(image);

//         if (carImagesArray.length > 0) {
//             const newIndex = carImagesArray.findIndex((img: any) => `${cloudfrontURL}${img.imageUrl}` === image);
//             if (newIndex !== -1) {
//                 setIndex(newIndex);
//             }
//         }
//     }, [image, carImagesArray]);

//     useEffect(() => {
//         const updateScreenType = () => {
//             const width = window.innerWidth;
//             if (width <= 480) {
//                 setScreenType("mobile");
//                 setIsMobile(true);
//             } else if (width <= 768) {
//                 setScreenType("bigMobile");
//                 setIsBigMobile(true)
//             } else if (width <= 1024) {
//                 setScreenType("tablet");
//                 setIsTablet(true);
//             } else {
//                 setScreenType("desktop");
//             }
//         };

//         updateScreenType();
//         window.addEventListener("resize", updateScreenType);
//         return () => window.removeEventListener("resize", updateScreenType);
//     }, []);


//     const handleSelect = (selectedIndex: number) => {
//         setIndex(selectedIndex);
//     };

//     const cloudfrontURL = process.env.REACT_APP_CLOUDFRONT_URL || "";

    // const handlePrev = () => {
    //     setIndex((prevIndex) => {
    //         const newIndex = prevIndex > 0 ? prevIndex - 1 : carImagesArray.length - 1;
    //         const originalImage = getOriginalUrlFromThumb(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
    //         setMainImage(originalImage);
    //         setSelectedMainImage(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
    //         return newIndex;
    //     });
    // };

    // const handleNext = () => {
    //     setIndex((prevIndex) => {
    //         const newIndex = prevIndex < carImagesArray.length - 1 ? prevIndex + 1 : 0;
    //         const originalImage = getOriginalUrlFromThumb(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
    //         setMainImage(originalImage);
    //         setSelectedMainImage(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
    //         return newIndex;
    //     });
    // };


//     const getImageDimensions = () => {
//         switch (screenType) {
//             case "mobile":
//                 return { width: "90vw", height: "30vh" };
//             case "bigMobile":
//                 return { width: "85vw", height: "35vh" };
//             case "tablet":
//                 return { width: "50vw", height: "40vh" };
//             default:
//                 return { width: "45vw", height: "55vh" };
//         }
//     };


//     const getRibbonStyle = () => {
//         switch (screenType) {
//             case "mobile":
//                 return { marginLeft: "0px" }; // Adjust as needed for mobile
//             case "tablet":
//                 return { marginLeft: "10px" }; // Adjust as needed for tablet
//             default:
//                 return { marginLeft: "10px" }; // Default for desktop
//         }
//     };


//     const badgeList: any = {
//         // assured: 'assured_badge',
//         sold: 'sold_orange_badge',
//         booked: 'booked_blue_badge'
//     }

//     const badgeUrl = process.env.REACT_APP_CLOUDFRONT_URL + `/public/${badgeList[badge]}.png`

//     return (
//         <div className="mt-3" style={{ position: "relative", display: "inline-block", width: getImageDimensions().width }}>
//             {/* Deal of the Day Ribbon */}
//             {isDealOfTheDay == 1 && (
//                 <div className="ribbons-wrapper" style={getRibbonStyle()}>
//                     <div className="ribbon">
//                         <span className="ribbon1">
//                             <span className="deal">Deal of <br /> the day</span>
//                         </span>
//                     </div>
//                 </div>
//             )}

//             {/* Carousel */}
//             {/* <div
//                 style={{
//                     position: "relative",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                     width: getImageDimensions().width,
//                     minWidth: getImageDimensions().width,
//                     maxWidth: "100%",
//                     overflow: "hidden"
//                 }}
//             > */}
//             <div className="d-flex align-items-center justify-content-center" style={{ overflow: "hidden" }}>
//                 <Carousel
//                     activeIndex={index}
//                     onSelect={handleSelect}
//                     interval={null}
//                     indicators={false}
//                     controls={false}
//                     fade
//                     style={{ width: "100%" }}
//                 >
//                     {carImagesArray.map((imgObj: any, idx: any) => (
//                         <div className="carousel-item" key={idx} style={{ width: "100%", minWidth: "100%" }}>
//                             <Image
//                                 src={ismainImage ? ismainImage : `${cloudfrontURL}${imgObj.imageUrl}`}
//                                 fluid
//                                 className="d-block"
//                                 alt={`Car Image ${idx + 1}`}
//                                 style={{
//                                     width: "100%",
//                                     height: getImageDimensions().height,
//                                     objectFit: "cover"
//                                 }}
//                             />
//                         </div>
//                     ))}
//                 </Carousel>

            //   <div
            //         style={{
            //             position: "absolute",
            //             top: "50%",
            //             left: "0px",
            //             transform: "translateY(-50%)",
            //             padding: isMobile ? "5px" : "10px",
            //             zIndex: 10,
            //             backgroundColor: "rgba(0, 0, 0, 0.5)"
            //         }}
            //   >
            //         {/* Left Arrow */}
            //         <button
            //             className="bg-transparent border-0 text-primary"
            //             onClick={handlePrev}
                       
            //         >
            //             <FontAwesomeIcon icon={faChevronLeft} size={'2x'} />
            //         </button>
            //   </div>

            //     {/* Right Arrow */}
            //    <div
            //         style={{
            //             position: "absolute",
            //             top: "50%",
            //             right: "0px",
            //             transform: "translateY(-50%)",
            //             padding: isMobile?"5px":"10px",
            //             zIndex: 10,
            //             backgroundColor: "rgba(0, 0, 0, 0.5)"
            //         }}>
            //         <button
            //             className="bg-transparent border-0 text-primary"
            //             onClick={handleNext}
            //         >
            //             <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
            //         </button>
            //    </div>
            // </div>

//             {/* Sale Badge */}
//             {badge && (
//                 <div
//                     className={
//                         isMobile
//                             ? "details-sale-badge-mobile"
//                             : isBigMobile
//                                 ? "details-sale-badge-Bigmobile"
//                                 : isTablet
//                                     ? "details-sale-badge-tablet"
//                                     : "details-sale-badge"
//                     }

//                     style={{
//                         position: "absolute",
//                         width: isMobile ? "80px" : "120px",
//                     }}
//                 >
//                     {badge !== "" && <Image src={badgeUrl} width="100%" />}
//                 </div>
//             )}
//             {/* Assured Badge */}
//             {isAssuredStatus == 1 && (
//                 <div className={screenType === "mobile" ? "details-assured-badge-mobile" : "details-assured-badge"}>
//                     <Image src={`${cloudfrontURL}/public/assured_badge.png`} width={screenType === "mobile" ? "100%" : "50%"} />
//                 </div>
//             )}
//         </div>
//     );
// };

// export default VehicleMainImage;


// import React from "react";
// import { Carousel, Image } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

// const VehicleMainImage: React.FC = () => {
//     return (
//         <Carousel fade>
//             <Carousel.Item interval={10000}>
//                 <Image
//                     className="d-block w-100"
//                     src="https://d2bnf6nvyu1v6i.cloudfront.net/44288ea0-a65e-11ef-9d5d-83b5eb8111ba/original/1732010927261.png"
//                     alt="First slide"
//                 />
//                 <Carousel.Caption>
//                     <h5>First slide label</h5>
//                     <p>Some representative placeholder content for the first slide.</p>
//                 </Carousel.Caption>
//             </Carousel.Item>

//             <Carousel.Item interval={2000}>
//                 <Image
//                     className="d-block w-100"
//                     src="https://d2bnf6nvyu1v6i.cloudfront.net/44288ea0-a65e-11ef-9d5d-83b5eb8111ba/original/1732010927261.png"
//                     alt="Second slide"
//                 />
//                 <Carousel.Caption>
//                     <h5>Second slide label</h5>
//                     <p>Some representative placeholder content for the second slide.</p>
//                 </Carousel.Caption>
//             </Carousel.Item>

//             <Carousel.Item>
//                 <Image
//                     className="d-block w-100"
//                     src="https://d2bnf6nvyu1v6i.cloudfront.net/44288ea0-a65e-11ef-9d5d-83b5eb8111ba/original/1732010927261.png"
//                     alt="Third slide"
//                 />
//                 <Carousel.Caption>
//                     <h5>Third slide label</h5>
//                     <p>Some representative placeholder content for the third slide.</p>
//                 </Carousel.Caption>
//             </Carousel.Item>
//         </Carousel>
//     );
// };

// export default VehicleMainImage;


import React, { useState, useEffect } from "react";
import { Carousel, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import HTTP from "../../config/axios";
import { getOriginalUrlFromThumb } from "../../config/utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const VehicleMainImage: React.FC<any> = ({ id, badge, isDealOfTheDay, isAssuredStatus, image, setSelectedMainImage, setMainImage, helloRampStatus }) => {
    const [carImagesArray, setCarImagesArray] = useState<any>([]);
    const [index, setIndex] = useState(0);
    const [ismainImage, setIsMainImage] = useState("");
    const cloudfrontURL = process.env.REACT_APP_CLOUDFRONT_URL || "";
    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);
    const [isBigMobile, setIsBigMobile] = useState(false); 
    const [screenType, setScreenType] = useState("desktop");


    useEffect(() => {
        const updateScreenType = () => {
            const width = window.innerWidth;
            if (width <= 480) {
                setScreenType("mobile");
                setIsMobile(true);
            } else if (width <= 768) {
                setScreenType("bigMobile");
                setIsBigMobile(true)
            } else if (width <= 1024) {
                setScreenType("tablet");
                setIsTablet(true);
                
            } else {
                setScreenType("desktop");
            }
        };

        updateScreenType();
        window.addEventListener("resize", updateScreenType);
        return () => window.removeEventListener("resize", updateScreenType);
    }, []);

    useEffect(() => {
        const getCarImages = async () => {
            if (!id) return;
            try {
                const response = await HTTP.get(`/api/vehicle/thumbnail-images/${id}`);
                const { data: { status, data } } = response;
                if (status === 1 && Array.isArray(data)) {
                    setCarImagesArray(data);
                } else {
                    setCarImagesArray([]);
                }
            } catch (error) {
                console.error("Error fetching images", error);
                setCarImagesArray([]);
            }
        };
        getCarImages();
    }, [id]);
    const handlePrev = () => {
        setIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : carImagesArray.length - 1;
            const originalImage = getOriginalUrlFromThumb(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
            setIsMainImage(originalImage);
            setMainImage(originalImage)
            setSelectedMainImage(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
            return newIndex;
        });
    };

    const handleNext = () => {
        setIndex((prevIndex) => {
            const newIndex = prevIndex < carImagesArray.length - 1 ? prevIndex + 1 : 0;
            const originalImage = getOriginalUrlFromThumb(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
            setIsMainImage(originalImage);
            setMainImage(originalImage)
            setSelectedMainImage(`${cloudfrontURL}${carImagesArray[newIndex]?.imageUrl}`)
            return newIndex;
        });
    };

    useEffect(() => {
        setIsMainImage(image);
        if (carImagesArray.length > 0) {

            const newIndex = carImagesArray.findIndex((img: any) => {
                const originalImageUrl = getOriginalUrlFromThumb(`${cloudfrontURL}${img.imageUrl}`);
                return originalImageUrl === image;
            });


            if (newIndex !== -1) {
                setIndex(newIndex);
            }
        }
    }, [image, carImagesArray]);

    const handleSelect = (selectedIndex: number) => {
        setIndex(selectedIndex);
        if (carImagesArray[selectedIndex]) {
            const originalImage = getOriginalUrlFromThumb(
                `${cloudfrontURL}${carImagesArray[selectedIndex].imageUrl}`
            );
            setIsMainImage(originalImage);
            setSelectedMainImage(`${cloudfrontURL}${carImagesArray[selectedIndex].imageUrl}`);
        }
    };

    const badgeList: any = {
        assured: 'assured_badge',
        sold: 'sold_orange_badge',
        booked: 'booked_blue_badge'
    }

    const badgeUrl = process.env.REACT_APP_CLOUDFRONT_URL + `/public/${badgeList[badge]}.png`

    const getRibbonStyle = () => {
        switch (screenType) {
            case "mobile":
                return { marginLeft: "0px" };
             case "tablet":
                return { marginLeft: "10px" };
             default:
                return { marginLeft: "10px" }; 
         }
    };

    return (
        <div className={isTablet?"mt-3":""} style={{ width: "100%", height: "auto", overflow: "hidden" }}>
            {isDealOfTheDay == 1 && (
                <div className="ribbons-wrapper" style={getRibbonStyle()}>
                    <div className="ribbon">
                        <span className="ribbon1">
                            <span className="deal">Deal of <br /> the day</span>
                        </span>
                    </div>
                </div>
            )}
            {helloRampStatus != 3 && (<Carousel activeIndex={index} onSelect={handleSelect} fade controls={false} indicators={false} interval={null} >
                {carImagesArray.map((imgObj: any, idx: number) => (
                    <Carousel.Item key={idx} style={{ width: "100%", height: "100%" }}>
                        <Image key={idx}
                            className="d-block w-100"
                            src={ismainImage ? ismainImage : `${cloudfrontURL}${imgObj.imageUrl}`}
                            alt={`Car Image ${idx + 1}`}
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                    </Carousel.Item>
                ))}
            </Carousel>)}

           

            {badge && (
                <div
                    className={
                        isMobile
                            ? "details-sale-badge-mobile"
                            : isBigMobile
                                ? "details-sale-badge-Bigmobile"
                                : isTablet
                                    ? "details-sale-badge-tablet"
                                    : "details-sale-badge"
                    }

                    style={{
                        position: "absolute",
                        width: isMobile ? "80px" : "120px",
                    }}
                >
                    {badge !== "" && <Image src={badgeUrl} width="100%" />}
                </div>
            )}

            
            {/* Assured Badge */}
            {isAssuredStatus == 1 && (
                <div className={screenType === "mobile" ? "details-assured-badge-mobile" : "details-assured-badge"}>
                    <Image src={`${cloudfrontURL}/public/assured_badge.png`} width={screenType === "mobile" ? "100%" : "50%"} />
                </div>
            )}
            {helloRampStatus!=3 && (<>  <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "0px",
                    transform: "translateY(-50%)",
                    padding: isMobile ? "5px" : "10px",
                    zIndex: 10,
                    backgroundColor: "rgba(0, 0, 0, 0.5)"
                }}
            >
                {/* Left Arrow */}
                <button
                    className="bg-transparent border-0 text-primary"
                    onClick={handlePrev}

                >
                    <FontAwesomeIcon icon={faChevronLeft} size={'2x'} />
                </button>
            </div>

                {/* Right Arrow */}
                <div
                    style={{
                        position: "absolute",
                        top: "50%",
                        right: "0px",
                        transform: "translateY(-50%)",
                        padding: isMobile ? "5px" : "10px",
                        zIndex: 10,
                        backgroundColor: "rgba(0, 0, 0, 0.5)"
                    }}>
                    <button
                        className="bg-transparent border-0 text-primary"
                        onClick={handleNext}
                    >
                        <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
                    </button>
                </div></>)}
        </div>
    );
};

export default VehicleMainImage;
