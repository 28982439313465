
import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen, faTrash, faUndo, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import ImageCellForDataTable from '../../common/ImageCellForDataTable';
import { useNavigate, createSearchParams } from 'react-router-dom';
import DropDownField from '../../form/DropDownField';
import { VehicleSaleStatus } from '../../../config/utils/helper';
import { downloadFileFromApi } from '../../../config/utils/helper';
import SimpleSpinner from '../../common/Spinner';
import { getFormatDDMMYYYY } from '../../../config/utils/dateFunctions';


const SellCarDataTable: React.FC<any> = ({ showModal, handleClose, show }) => {
    const [vehicleVehicleData, setVehicleVehicleData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const { control, reset, watch } = useForm<Record<string, any>>({ defaultValues: { vehicleStatus: '', saleStatus: '', search: "" } });
    const navigate = useNavigate();
    const [outletArray, setOutletArray] = useState<any[]>([])
    


    useEffect(() => {
        try {
            const getOutlet = async () => {
                const locationData = await HTTP.get(`/api/location/outlet/fetch-all`, { params: { status: 1 } });
                const { data: { status, data, msg, error } } = locationData

                if (status == 1) {
                    console.log("data.rows", data.rows)
                    setOutletArray(data.rows)
                } else {
                    setOutletArray([])
                }
            }
            getOutlet()
        }
        catch (err) {

        }
    }, [])

    const getOutletName = (outletId: number, outletArray: any[]) => {
        if (!Array.isArray(outletArray)) return "N/A"; // Ensure it's an array
        return outletArray.find((outlet) => outlet.id === outletId)?.name || "N/A";
    };

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    const columns: any = [
       , {
            name: <div>Name</div>,
            selector: (row: { makeName: string; modelName: string; variantName: string }) => {
                return (`${row.makeName} ${row.modelName} ${row.variantName}`)
            },

            wrap: true,
            sortable: true,
            keyName: 'makeName',
            width: "15%"
        }, {
            name: 'Price',
            selector: (row: { price: any; }) => row.price,
            grow: 0,
            sortable: true,
            keyName: 'price',
            width: "11.5%"
        }, 
        {
            name: 'Km Driven',
            selector: (row: { kmDriven: any; }) => {
                return <span title={row.kmDriven}> {row.kmDriven}</span>
            },
            width: "10.5%",
            keyName: 'kmDriven'
        },
       , {
            name: "Name",
            selector: (row: { firstName: string; lastName: string }) => {
                return row.firstName ? (
                    <span title={`${row.firstName} ${row.lastName}`}>
                        {row.firstName} {row.lastName}
                    </span>
                ) : '';
            },
            keyName: 'address',
            width: "11.5%",
            wrap: true,
        }, {
            name: <div> Phone number </div>,
            selector: (row: { phone: any; }) => row.phone,
            sortable: true,
            keyName: 'phone',
            width: "12.5%"
        }, 
        {
            name: <div> Address & Landmark </div>,
            selector: (row: { address: any; landmark: any }) => {
                return row.address ? <span title={row.address + ', ' + row.landmark}>{row.address}, {row.landmark}</span> : ''
            },
            // width:'140px',
            keyName: 'address',
            width: "12.5%",
            wrap: true,
        },
        {
            name: <div>Evaluation Date</div>,
            selector: (row: { evaluationType: number; evaluationDate: string | null }) => {
                return row.evaluationType !== 1 && row.evaluationDate ? (
                    <span title={`Evaluation Date: ${getFormatDDMMYYYY(row.evaluationDate)}`}>
                        {getFormatDDMMYYYY(row.evaluationDate)}
                    </span>
                ) : '';
            },
            keyName: 'evaluationDate',
            width: "12.5%",
            wrap: true,
        },
        {
            name: <div>Outlet Name</div>,
            selector: (row: { evaluationType: number; outletName:string | null }) => {
                return row.evaluationType !== 1 && row.outletName ? (
                    <span title={`Outlet: ${row.outletName}`}>
                        {row.outletName}
                    </span>
                ) : "";
            },
            keyName: 'outletName',
            width: "12.5%",
            wrap: true,
        }

       
    ];

    interface IgetAllVehicle {
        page: number, limit: number,
        searchText?: string,
        sortColumn?: { sortColumn: string, sortDirection: string },
    }


    const vehicleStatusType = watch('vehicleStatus')
    const saleStatusType = watch('saleStatus')

    //Fetching the intial data for the data table
    useEffect(() => {
        const getAllVehicle = async () => {
            // setIsLoading(true);
            const params = {
                searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                saleStatus: VehicleSaleStatus.VEHICLE_PENDING_USER_INFO,
            };
            const response = await HTTP.get(`/api/admin/enquiry/fetch-sellCar/${currentPage}/${perPage}`, { params });
            const { data: { status, data, msg, error, totalRecords } } = response;
            setVehicleVehicleData(data.rows);
            setTotalRows(data.count.length)
            // setIsLoading(false)
        }
        getAllVehicle()
    }, [show, currentPage, perPage, searchText, sortDetails, removedIds, vehicleStatusType, saleStatusType]);


    const currentSearchText = watch('search');
    useEffect(() => {
        setSearchText(currentSearchText)
    }, [currentSearchText])

    const getAllVehicleExcel = async () => {
        try {
            setIsLoading(true);
            const params = {
                searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                saleStatus: VehicleSaleStatus.VEHICLE_PENDING_USER_INFO,
            };
            const response = await HTTP.get(`/api/admin/enquiry/fetch-sellCar/excel`, { params, responseType: 'blob', });
            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            downloadFileFromApi(response.data, filename)
            setIsLoading(false);
        }
        catch (error) {
            console.log("Error in enquery download api", error);
            setIsLoading(false);
        }

    }



    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };


    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }
    return (<>

        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Sell Car list</h2></div>
                <div className="p-2 mt-2 text-success" title="Download Excel Report">
                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={getAllVehicleExcel} style={{ cursor: 'pointer' }} />
                </div>
                <div className="p-2">
                    <InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} />
                </div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={vehicleVehicleData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />


        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />

        <SimpleSpinner show={isLoading} />

    </>)
}

export default SellCarDataTable;