import { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import { faFileExcel, faSearch } from "@fortawesome/free-solid-svg-icons";
import DataTable from 'react-data-table-component';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { getFormatDDMMYYYY } from '../../../config/utils/dateFunctions';
import SimpleToast from '../../common/Toast';
import SimpleSpinner from "../../common/Spinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import CheckBox from '../../form/Checkbox';
import CustomModal from '../../common/CustomModal';
import ReferAndEarnModal from './ReferAndEarnModal';
import { downloadFileFromApi } from '../../../config/utils/helper';

const ReferAndEarnTable = () => {
    const { control, watch, getValues } = useForm<Record<string, any>>({ defaultValues: { leads: 'All', search: "", contactStatus: "All" } });
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [enquiryData, setEnquiryData] = useState([]);
    const [checkedStatus, setCheckedStatus] = useState<{ [key: number]: boolean }>({});
    const searchText = watch('search');
    const leadStatus = watch('leads');
    const navigate = useNavigate();
    const contactStatus = watch('contactStatus');
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const archivedReferalRecords = watch('archived')
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
      const [isOpenModal, setIsOpenModal] = useState(false);
      const [openActivateModal, setOpenActivateModal] = useState(false)
      const [removedIds, setRemovedIds] = useState<any>([])
      const [showAddModal, setShowAddModal] = useState(false);
      const [userIdToEdit, setUserIdToEdit] = useState(null);
        const [userDataToEdit, setUserDataToEdit] = useState({});

    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    useEffect(() => {
        try {
            const getAllEnquiries = async () => {
                setIsLoading(true);
                 const referalRecordStatus = archivedReferalRecords ? 0 : 1
                const params = {
                    searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                    leadStatus: leadStatus, contactStatus: contactStatus,
                    status: referalRecordStatus, currentPage,          // Ensure currentPage is sent
                    perPage, 
                };
                const response = await HTTP.get(`/api/admin/refer-and-earn/fetch-all/${currentPage}/${perPage}`, { params });
                const { data: { status, data, msg, error } } = response;
                setEnquiryData(data.rows)
                setTotalRows(data.count)
            }
            getAllEnquiries()
        }
        catch (e) {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        finally {
            setIsLoading(false)
        }


    },[currentPage, perPage, searchText, sortDetails, leadStatus, contactStatus,showAddModal,archivedReferalRecords,removedIds]);

    //Options for lead status

    //Defining the column for the data table
    const column: any = [
        {
            name: 'Name',
            selector: (row: { name: string }) => row.name,
            sortable: true,
            keyName: 'name',
            width: '180px',
            cell: (row: { name: string }) => <span title={row.name}>{row.name}</span>,
        },
        {
            name: 'Contact No',
            selector: (row: { phone: string }) => row.phone,
            keyName: 'phone',
            width: '120px',
            cell: (row: { phone: string }) => <span title={row.phone}>{row.phone}</span>,
        },
        {
            name: 'State',
            selector: (row: { states: string }) => row.states,
            keyName: 'states',
            width: '100px',
            cell: (row: { states: string }) => <span title={row.states}>{row.states}</span>,
        },
        {
            name: 'District',
            selector: (row: { districts: string }) => row.districts,
            keyName: 'districts',
            width: '180px',
            cell: (row: { districts: string }) => <span title={row.districts}>{row.districts}</span>,
        },
        {
            name: 'Type',
            selector: (row: { referType: number }) => row.referType == 1 ? "Buy" : "Sell",
            keyName: 'referType',
            width: '80',
            cell: (row: { referType: number }) => (
                <span title={row.referType == 1 ? "Buy" : "Sell"}>
                    {row.referType == 1 ? "Buy" : "Sell"}
                </span>
            ),
        },
        {
            name: 'Created At',
            sortable: true,
            selector: (row: { createdAt: string }) =>
                getFormatDDMMYYYY(row.createdAt),
            width: '180px',
            cell: (row: { createdAt: string }) => {
                const formattedDate = getFormatDDMMYYYY(row.createdAt);
                return <span title={formattedDate}>{formattedDate}</span>;
            },
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div style={{ display: 'flex', gap: '10px' }}>
                    {!archivedReferalRecords ? (
                        <>
                            <Button
                                variant="success"
                                size="sm"
                                title="Edit info"
                                onClick={() => handleEditClick(row.id, row)}
                            >
                                <FontAwesomeIcon icon={faPen} />
                            </Button>

                            <Button
                                size="sm"
                                title="Remove"
                                onClick={() => handleDeleteClick(row.id, row.title)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="success"
                            size="sm"
                            title="Make active"
                            onClick={() => handleActivateClick(row.id, row.title)}
                        >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>
                    )}
                </div>
            ),
            center: true,
            width: '200px',
        }
    ];


    //Handling the page changes
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }
     const addReferal = () => {
    setShowAddModal(true);

}

   const handleAddCloseModal = () => {
    setShowAddModal(false);
    setUserIdToEdit(null);
    setUserDataToEdit({});
  
};
   const handleEditClick = (userId:any,userData:any) => {
    console.log("userData",userData)
        setUserIdToEdit(userId);
        setUserDataToEdit(userData)
        setShowAddModal(true);
    };

     const handleRecoverReferal = async ()=>{
        setIsOpenModal(false)
        setOpenActivateModal(false)
        setIsLoading(true);
         try {
        const response = await HTTP.put(`/api/recover-referral`, {
            id: selectedRecord,
            status:1,
            });

        const { data: { status, message, type } } = response;

        if (status === 1) {
              setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message:' Referal Recovered Successfully', variant: 'success' })
         
        } else {
              setToast({ open: true, header: 'Failed', message: 'Referal title already exist', variant: 'danger' })
           
        }
         setIsLoading(false);
    } catch (error) {
        // Handle error if the request fails
        console.error('Error Recovering Referal', error);
        setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' });
    }

    }

  const handleDeleteClick = async (id: number, title: string) => {
   
    setSelectedRecord(id);
    setIsOpenModal(true);
}

    const handleActivateClick = (id: number , title: string) => {

        setSelectedRecord(id);
        setOpenActivateModal(true)
    }
    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false);
    }

    const handleReferalRecordStatus = async (isDelete = true) => {
        setIsOpenModal(false)
        setOpenActivateModal(false)
        setIsLoading(true);
        const response = await HTTP.put(`/api/admin/refer-and-earn/update-status`, { id: selectedRecord, status: isDelete ? 0 : 1});
        const { data: { status, error, message } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: isDelete ? 'Referal Removed Successfully' : 'Referal Recovered Successfully', variant: 'success' })
        } else {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
        setIsLoading(false);
    }


    const getAllReferAndEarnExcel = async () => {
        try {
            setIsLoading(true);
            const referalRecordStatus = archivedReferalRecords ? 0 : 1
            const params = {
                searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                leadStatus: leadStatus, contactStatus: contactStatus,
                status: referalRecordStatus
            };
            const response = await HTTP.get(`/api/admin/refer-and-earn/fetch-all/excel`, { params, responseType: 'blob', });
            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            downloadFileFromApi(response.data, filename)
            setIsLoading(false);
        }
        catch (error) {
            console.log("Error in enquery download api", error);
            setIsLoading(false);
        }

    }

    return (<>
        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Refer&nbsp;and&nbsp;earn</h2></div>
                <div className="p-2 mt-2 text-success" title="Download Excel Report">
                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={getAllReferAndEarnExcel} style={{ cursor: 'pointer' }} />
                </div>

                   <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <CheckBox name="archived" control={control} label="Show removed referals"
                        classList="" /></div>
                <div className="p-2">
                    <Button onClick={addReferal} variant="success"> Add&nbsp;Referal </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={column}
            data={enquiryData}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />
        <CustomModal isOpen={isOpenModal} title="Delete Referal"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleReferalRecordStatus(true)}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover Referal"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => handleRecoverReferal()}>
                    Confirm
                </Button></>} />

                <ReferAndEarnModal
                show={showAddModal}
                handleClose={handleAddCloseModal}
                referOptions={[
                    { id: 1, name: "Buy", keyname: "buy" },
                    { id: 2, name: "Sell", keyname: "sell" }

                ]}
                userIdToEdit={userIdToEdit}
                userDataToEdit={userDataToEdit}
            />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />
        <SimpleSpinner show={isLoading} />

    </>)

}

export default ReferAndEarnTable