import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash, faUndo } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Checkbox from '../../form/Checkbox';
import DropDownField from '../../form/DropDownField';
import { startOfYear } from 'date-fns';


const VariantTable: React.FC<any> = ({ newVariant, editModalDetails, addEditCount }) => {
    const [vehicleVariantData, setVehicleVariantData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [selectedYear, setSelectedYear] = useState<number | null>(null);
    const [selectedModelId, setSelectedModelId] = useState<number | null>(null);
    const [selectedName, setSelectedName] = useState<string | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [model, setModel] = useState('all')
    const [removedIds, setRemovedIds] = useState<any>([])
    const { control, reset, watch } = useForm();
    const archivedMake = watch('archived')
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const currentSearchText = watch('search');
    const [makeArray, setMakeArray] = useState([])
    const makeList = watch("makeList")

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    //Column Declaraion for the data table
    const columns: any = [
        {
            name: 'Name',
            selector: (row: { name: string; }) => row.name,
            sortable: true,
            keyName: 'name'
        },
        {
            name: 'Model Name',
            selector: (row: { modelName: any; }) => row.modelName,
            sortable: true,
            keyName: 'modelName'
        },
        {
            name: 'Action',
            cell: (row: any) => (
                <div>
                    {!archivedMake ? <><Button variant="success" size="sm" className="me-3" onClick={() => editVariant(row)} title="Edit info" >
                        <FontAwesomeIcon icon={faPen} />
                    </Button> <Button size="sm" className="me-3" onClick={() => handleDeleteClick(row.id)} title="Remove make" >
                            <FontAwesomeIcon icon={faTrash} />  </Button></> :
                        <Button variant="success" size="sm" className="me-3" onClick={() => handleActivateClick(row.id, row.modelId, row.name, row.startYear)} title="Make active" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>}
                </div>
            ),
            center: true,
        }
    ];

    useEffect(() => {
        const getMakeOptions = async () => {
            const response = await HTTP.get(`/api/make/all`);
            const { data: { status, data, msg, error } } = response
            if (status == 1) {
                setMakeArray(data)
            } else {
                setMakeArray([])
            }
        }
        getMakeOptions()
    }, [])

    //Fetching the intial data for the data table
    useEffect(() => {
        const getAllVariants = async () => {
            setIsLoading(true);
            interface QueryParams {
                searchText?: string;
                sortDetails?: {};
                status?: number;
                makeData?: number;
            }
            const params: QueryParams = {};
            params.searchText = currentSearchText
            params.makeData = makeList
            if (sortDetails) {
                params.sortDetails = JSON.stringify(sortDetails)
            }
            params.status = archivedMake ? 0 : 1;
            const response = await HTTP.get(`/api/admin/variant/fetch-all/${currentPage}/${perPage}`, { params });
            const { data: { status, data, msg, error, totalRecords } } = response;
            setVehicleVariantData(data.rows);
            setTotalRows(data.count);
            setIsLoading(false);

        }
        getAllVariants()
    }, [addEditCount, currentPage, perPage, currentSearchText, sortDetails, model, removedIds, archivedMake, makeList]);

    //Invokng the edit modal for editing the model
    const editVariant = (model: any) => {
        editModalDetails(model)
    }

    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
        //setIsLoading(true);
    };

    //handling Delete of models
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }
    const handleActivateClick = (id: number,modelId:number,name:string,year:number) => {
        setSelectedName(name)
        setSelectedModelId(modelId)
        setSelectedRecord(id);
        setSelectedYear(year)
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false)
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        const response = await HTTP.patch(`/api/admin/variant/remove/${selectedRecord}`);
        const { data: { status, data, totalCount, message, error, type } } = response
        if (status == 1) {
            //getAllModels({page:currentPage,limit:limit,makeId:'makeAll'})
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: 'Variant deleted successfully', variant: 'success' })
        } else if (status == 0 && type == "assigned") {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed', message: message, variant: 'danger' })

        } else {
            setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })
        }
    }

    const handleRecover = async () => {
        setOpenActivateModal(false)
        setIsLoading(true);

        const response = await HTTP.patch(`/api/admin/variant/recover/${selectedRecord}`,{
            id: selectedRecord,
            modelId:selectedModelId,
            name: selectedName,
            startYear:selectedYear
        
        });
        const { data: { status, data, totalCount, msg, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setIsLoading(false);
            setToast({ open: true, header: 'Success', message: 'Variant recovered successfully', variant: 'success' })
        } else {
            setIsLoading(false);
            setToast({ open: true, header: 'Failed',  message: msg?msg:'Something went wrong', variant: 'danger' })

        }
        setIsLoading(false);
    }

    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
        //getAllModels({page:1,limit,'makeId',columnDetails})
    }

    return (<>


        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Vehicle variants</h2></div>
                <div className="p-2">
                    <DropDownField label="" name="makeList" options={makeArray} control={control} placeholder="Choose make" />
                </div>
                <div className="p-2 mt-2"><Checkbox name="archived" control={control} label="Show removed variants"
                    classList="" /></div>
                <div className="p-2">
                    <Button variant="success" onClick={newVariant}>
                        Add variant
                    </Button>
                </div>
                <div className="p-2"><InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} /></div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={vehicleVariantData}
            //progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete  Variant"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />
        <CustomModal isOpen={openActivateModal} title="Recover variant"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />
        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />


    </>)
}

export default VariantTable;