import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form';
import { Image } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import InputTextField from "../../form/InputTextField";
import { fieldRequired } from '../../../config/validations/rules';
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import HTTP from "../../../config/axios";
import DropDownField from "../../form/DropDownField";
import SimpleToast from "../../common/Toast";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { useLocation, useNavigate } from "react-router-dom";

const LocateUs = () => {

    const [locationArray, setLocationArray] = useState([]);
    const [toast, setToast] = useState({
        open: false,
        header: "",
        message: "",
        variant: "",
    });
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const locateUsRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        const getAllLocation = async () => {
            try {
                const locationData = await HTTP.get(`/api/location/fetch-all`, { params: { status: 1 } });

                if (locationData?.data) {
                    const { status, data } = locationData.data; // Destructuring safely

                    if (status === 1) {
                        setLocationArray(data);
                    } else {
                        setLocationArray([]);
                    }
                } else {
                    console.error("No data in response");
                    setLocationArray([]);
                }
            } catch (error) {
                console.error("Error fetching locations:", error);
                setLocationArray([]);
            }
        };

        getAllLocation();
    }, []);

    const { register, handleSubmit, formState: { errors }, setError, clearErrors, control, watch, reset, getValues, setValue } = useForm();
    const onSubmit = async (formData: any) => {
        try {
            formData.saleLead = 50;
            const responseType = await HTTP({
                method: "post",
                url: '/api/leads/contact-us/',
                data: formData,
            });
            const {
                data: { data, status, message },
            } = responseType
            setToast({
                open: true,
                header: status ? "Success" : "Warning",
                message,
                variant: status ? "success" : "danger",
            });
            setTimeout(() => {
                window.open('https://www.popularmaruti.com/location-map', '_blank');
            }, 3000);


        } catch (error) {
            setToast({
                open: true,
                header: "Failed",
                message: "Something went wrong",
                variant: "danger",
            });
        } finally {
            reset()
        }
    };

    useEffect(() => {
        const hash = currentLocation.hash;
        if (hash === '#locate' && locateUsRef.current) {
            setTimeout(()=>{
                locateUsRef?.current?.scrollIntoView({ behavior: 'smooth' });
            },1000)
        }
      }, [currentLocation]);
    
      const handleSignIn = () => {
        const formValues = getValues();
        encryptLocalStorage.setItem("locateDetails", JSON.stringify(formValues));
        encryptLocalStorage.setItem("previousLoggedoutLocation",currentLocation.pathname+"#locate")
        navigate("/sign-in")
      }
    
      useEffect(() => {
        const storedData = encryptLocalStorage.getItem("locateDetails");
        encryptLocalStorage.removeItem("locateDetails");
        if (storedData) {
            reset(storedData);
        }
      }, []);
         useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
       if(token){
         try{
           
            const fetchUser = async () => {
            const response = await HTTP.get(`/api/fetch-profile`);
            const { data: { status, data, msg, error } } = response
            if(status==1){
                setValue('name', `${data.firstName} ${data.lastName}`);
                setValue('mobileNo', data.phone)

            }
          
        }
        fetchUser()
        }
        catch(error){
            console.log("Error in states fetch API", error)
        }
       }
        
   
    }, [])

    return (
        <div className="App" ref={locateUsRef}>
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col md={12}>
                            <div className="text-center pb-4">
                                <h2 className="text-primary head-bold"> LOCATE US FOR SUPPORT </h2>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="pb-5">
                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/map.png`} width={"100%"} />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='mb-3'>
                                <InputTextField name="name" label="Name"
                                    placeholder="Enter name" clearErrors={clearErrors}
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                            <div className='mb-3'>
                                <InputTextField name="mobileNo" label="Mobile number"
                                    placeholder="Enter mobile number" clearErrors={clearErrors}
                                    control={control} rule={{ required: fieldRequired }} />
                            </div>
                            <div className="mb-3">
                                <DropDownField
                                    name="locationId"
                                    label="Location"
                                    options={locationArray}
                                    control={control}
                                    rule={{ required: fieldRequired }}
                                />
                            </div>
                            <Col lg={4} md={5}>
                                {isLoggedIn ? (<Button variant="primary" type="submit" >
                                    Submit
                                </Button>) : ( 
                                <Button variant="primary" onClick={handleSignIn}>
                                    Contact&nbsp;us
                                </Button> )}
                            </Col>
                        </Col>
                    </Row>
                </Form>
            </Container>
            <SimpleToast
                show={toast.open}
                header={toast.header}
                message={toast.message}
                variant={toast.variant}
                onClose={() => setToast({ ...toast, open: false })}
            />
        </div >
    );
};

export default LocateUs;

