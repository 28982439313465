import { Fragment, useEffect, useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css"
import HTTP from "../../../config/axios";
import FeaturesModal from "./FeaturesModal";
import { useLocation, useNavigate } from "react-router-dom";
import { encryptLocalStorage } from "../../../config/utils/storage";

const FeaturesList: React.FC<any> = ({ id, isMobile }: { id: string; isMobile :boolean}) => {
    const [featuresList, setFeaturesList] = useState<any>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const currentLocation = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);



    useEffect(() => {
        const getCarFeatures = async () => {
            const featuresResponse = await HTTP.get(`/api/vehicle/features/fetch-all/${id}`);
            const { data: { status, data, msg, error } } = featuresResponse;
            if (status == 1) {
                const featureDataArray: any = [];
                let dataArray: any = []
                data.map((element: any, index: number) => {
                    if (isMobile) {
                        featureDataArray.push(element)
                    } else {
                        if ((index + 1) % 3 == 1 && index != 0) {
                            featureDataArray.push(dataArray)
                            dataArray = []
                            dataArray.push(element)
                        } else {
                            dataArray.push(element)
                        }
                        if (index + 1 === data.length) {
                            featureDataArray.push(dataArray)
                        }
                    }
                })
                setFeaturesList(featureDataArray)

            } else {
                setFeaturesList([])
            }
        }
        getCarFeatures()
    }, [id, isMobile]);

    const openModal = () => {
        if (!isLoggedIn) {
            encryptLocalStorage.setItem("previousLoggedoutLocation", currentLocation.pathname)
            navigate("/sign-in")
        }
        else {
            setIsModalOpen(true);
        }
    };

    return (
        <>
            {!isMobile ? (
                <>
                    <h3 className="text-dark is-DetailFeatures mt-3">Car features</h3>
                    <div className="border rounded px-3">
                        {featuresList && featuresList.map((element: any, index: number) => {
                            if (index >= 3) {
                                return null; // Stop execution beyond the 9th loop if isLimited is true
                            }
                            return (
                                <Table className="mt-1 w-100">
                                    <thead>
                                        <tr className="custom-tr">
                                            {element.length > 0 && element?.map((features: any, index: number) => (
                                                <th key={index} title={`${features.name}`} className="custom-th align-top" style={{ width: '33.33%' }}>
                                                    <p className="custom-p text-secondary fw-bold">{features.name}</p>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {element.length > 0 && element?.map((features: any, index: number) => (
                                                <td key={index} className="custom-td align-top" style={{ width: '33.33%' }}>
                                                    <p className="is-DetailValues fw-bold">{features.value}</p>
                                                </td>
                                            ))}
                                        </tr>
                                    </tbody>
                                </Table>

                            )
                        })}
                        {featuresList.length != 0 ? (
                            <div className="d-flex justify-content-end">

                                <Button variant="primary" onClick={openModal} className="my-2">
                                    View all features
                                </Button>

                            </div>

                        ) : (
                            <p>No features added</p>
                        )}
                    </div>
                </>) : (
                <>
                        <h3 className="text-dark is-DetailFeatures pt-3 ps-0">Car features</h3>
                    <div className="border rounded mt-2">
                        <>
                            {featuresList && featuresList.map((features: any, index: number) => {
                                if (index >= 9) {
                                    return null; // Stop execution beyond the 9th loop if isLimited is true
                                }
                                return (
                                    <Fragment key={index}>
                                        <div className="pt-2">
                                            <p className="text-secondary fw-bold">{features.name}</p>
                                            <p className="is-DetailValues fw-bold">{features.value}</p>

                                        </div>
                                        <div className="border-bottom" />
                                    </Fragment>
                                )
                            })}
                            {featuresList.length != 0 ? (
                                <div className="d-flex justify-content">
                                    <Button variant="primary" onClick={openModal} className="my-2 w-100">
                                        View all features
                                    </Button>
                                </div>
                            ) : (
                                <p>No features added</p>
                            )}
                        </>
                    </div>
                </>
            )
            }
            {isModalOpen && (
                <FeaturesModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} id={id} />
            )}
        </>
    );
};

export default FeaturesList;