import { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css"
import HTTP from "../../../config/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleCheck,
    faCircleExclamation
} from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from "react-bootstrap";
import InspectionModal from "./InspectionModal";
import { InspectionTypeOptions } from "../../../config/utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { encryptLocalStorage } from "../../../config/utils/storage";

// import InspectionReportModal from "./InspectionReportModal";

const DamageReport: React.FC<any> = ({ id, report = null }) => {
    const [damageList, setDamageList] = useState<any>([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inspectionList, setInspectionList] = useState<{ note: string, fixed: number, inspectionType: number, inspectionId: number, keyName: string, name: string , imperfection_note:string, tested_note:string}[]>([]);
    const currentLocation = useLocation()
    const navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = encryptLocalStorage.getItem("jwtToken");
        if (token) {
          setIsLoggedIn(true)
        }
      }, []);

    useEffect(() => {
        const getCarInspections = async () => {
            const inspectionResponse = await HTTP.get(
                `/api/vehicle/inspection/fetch-all/${id}`
            );
            const {
                data: { status, data, msg, error },
            } = inspectionResponse;
            if (status == 1) {
                setInspectionList(data);
            } else {
                setInspectionList([]);
            }
        };
        getCarInspections();
    }, [id]);
    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleOpenSignInPage = () => {
        encryptLocalStorage.setItem("previousLoggedoutLocation",currentLocation.pathname)
        navigate("/sign-in/")
    }

    const InspectionReport = () => {
        return (
            <>
                {InspectionTypeOptions.map(option => {
                    const optionId = option.id;
                    if (optionId <= 1 ){ 
                        return false;
                    }
                    // Filter inspections for the current option ID
                    const fixedInspections = inspectionList.filter(inspection => inspection.inspectionType === optionId && inspection.note !='' );
                    const fixedCount = fixedInspections.length;

                    const unfixedInspections = inspectionList.filter(inspection => inspection.inspectionType === optionId && inspection.imperfection_note != '');
                    const unfixedCount = unfixedInspections.length;

                    return (
                        <Row key={option.id} className="d-flex my-2 border-bottom mt-4">
                            <Col md={6} xs={6}  className="me-auto fw-semibold text-dark">
                                {option.name}
                            </Col>
                            { unfixedCount != 0 && <Col md={2} xs={3} className="ps-0">
                              <FontAwesomeIcon icon={faCircleExclamation} className="text-primary fs-6"/>
                              <span className="fs-14">&nbsp;{unfixedCount}</span> 
                            </Col>}
                            <Col md={2} xs={3} className="px-0">
                                <FontAwesomeIcon icon={faCircleCheck} className="text-success fs-6"/>
                               <span className="fs-14">&nbsp;{fixedCount}</span> 
                            </Col>
                          
                        </Row>
                    );
                })}
            </>

        )
    }


    return (
        <>
            <h3 className="text-dark is-DetailFeatures ps-0 pt-2">{report === "detail" ? "Inspection Report" : "Damage Report"}</h3>
            <div className="border rounded p-3 mt-2">
                <InspectionReport />
                <div className="d-flex justify-content-end">
                   { isLoggedIn ? <Button variant="primary" onClick={openModal} className="my-2">
                        View full details
                    </Button> : <Button variant="primary" onClick={handleOpenSignInPage} className="my-2">
                        View full details
                    </Button>}
                </div>
            </div>
            {isModalOpen && <InspectionModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} id={id} />}
        </>

    );

};

export default DamageReport;
