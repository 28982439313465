import { subYears, getYear, format, parse, isToday, formatDistance, addDays, differenceInDays } from "date-fns"


// return year
export const getYearValue = (reductionNo: number) => {
   if (!reductionNo) return reductionNo
   return getYear(subYears(new Date(), reductionNo))
}

// return date in foramt Jun 01 2022 
export const getFormatMMMddyyy = (date: string) => {
   if (!date) return date
   return format(new Date(date), 'MMM dd yyyy');
}

// return date in format Nov 2022
export const getFormatMMMYYYY = (date: string) => {
   if (!date) return date
   return format(new Date(date), 'MMM yyyy')
}

export const getFormatEEEDoMMMyyyy = (date: string) => {
   if (!date) return date
   return format(new Date(date), 'EEEE, do MMM yyyy');
}

export const getFormatyyyymmdd = (date: Date) => {
   if (!date) return date   
   return format(new Date(date), 'yyyy-MM-dd');
}

export const getFormatTimehhmmaa = (date: string) => {
   if (!date) return date
   return format(new Date(date), 'hh:mm aa');
}

// return year from yyy-mm-dd 
export const getFormatYYYY = (date: string) => {
   if (!date) return date
   const parsedDate = parse(date, 'yyyy-MM-dd', new Date());
   return getYear(parsedDate);
}

// return year from yyyy-MM-dd'T'HH:mm
export const getFormatyyyyMMddTHHmm = (date: string) => {
   if (!date) return date
   return  format(new Date(date), "yyyy-MM-dd'T'HH:mm");
}


export const getLongFormatOfDateAndTime = (date:string)=>{
   if (!date) return date
   return format(new Date(date), 'do MMM yyyy , p');
}

export const dateIsToday = (date:string)=>{
   if(!date) return date
   return isToday(new Date(date))
}

export const dateTimeDistance = (date:string)=>{
   if(!date) return date
   return formatDistance(new Date(date), new Date(), { addSuffix: true })
}

export const  getClientTimeZone = ()=>{
   return Intl.DateTimeFormat().resolvedOptions().timeZone
}

//Adding or subtracting a day from the given date
export const  addDay = (date:string,numberToadd:number)=>{
   if(!date) return date
   return addDays(new Date(date),numberToadd)
}

//Find the difference in Date
export const  differenceInDay = (date1:string,date2:string)=>{
   if(!date1 || !date2) return date1
   return differenceInDays(new Date(date1),new Date(date2))
}

// return year from yyy-mm-dd 
export const getParsedDate = (date: string) => {
   if (!date) return date
   return parse(date, 'dd-MM-yyyy HH:mm:ss', new Date());

}


export const getFormatDDMMYYYY = (date: string | Date) => {
   if (!date) return date;
   return format(new Date(date), 'dd-MM-yyyy');
};

export const getLongFormatOfDateAndTimes = (date: string) => {
   if (!date) return date
   return format(new Date(date), 'do MMM yyyy , p');
}
