    import React, { useEffect, useState } from "react";
    import Carousel from "react-multi-carousel";
    import "react-multi-carousel/lib/styles.css";
    import { Col, Image, Row } from "react-bootstrap";
    import HTTP from "../../config/axios";
    import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
    import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2,
            slidesToSlide: 1,
            partialVisibilityGutter: 70
        },
        tablet: {
            breakpoint: { max: 1024, min: 700 },
            items: 2,
            slidesToSlide: 1,
            partialVisibilityGutter: 70
        },
        mobile: {
            breakpoint: { max: 700, min: 0 },
            items: 2,
            slidesToSlide: 1,
            partialVisibilityGutter: 20
        }
    };
    interface IImages {
        id: number;
        imageUrl: string;
    }
    type MultiCarouselProps = {
        id: number | string;
        onCarouselImageClick: any
        detailPage?: boolean
        isMobile?: boolean
        selectedMainImage?: string
        helloRampStatus?: number
    };
const MultiCarousel: React.FC<MultiCarouselProps> = ({ id, onCarouselImageClick, detailPage, isMobile, selectedMainImage, helloRampStatus }) => {

        const [carImagesArray, setCarImagesArray] = useState<any>([]);
        const carouselRef = React.useRef<any>(null);
        const [selectedImage, setSelectedImage] = useState<string | null>(null);


    useEffect(() => {
        if (selectedMainImage) {
            setSelectedImage(selectedMainImage);
        }
    }, [selectedMainImage]);


        const handleImageClick = (imageUrl: string) => {
            setSelectedImage(imageUrl);
            onCarouselImageClick(imageUrl);
        };

        useEffect(() => {
            const getCarImages = async () => {
                const response = await HTTP.get(`/api/vehicle/thumbnail-images/${id}`);
                const { data: { status, data, msg, error } } = response
                if (status == 1) {
                    setCarImagesArray(data)
                } else {
                    setCarImagesArray([])
                }
            }
            getCarImages()
        }, [id]);


        const handle360View = () => {
            window.open('/image-view-360', '_blank')
        }

        const handlePrevClick = () => {
            if (carouselRef.current) {
                carouselRef.current.previous();
            }
        };

        const handleNextClick = () => {

            if (carouselRef.current) {
                carouselRef.current.next();
                console.log(carouselRef.current)
            }
        };

        const CustomLeftArrow = () => (
            <button className="bg-light border-0 text-primary" onClick={handlePrevClick}>
                <FontAwesomeIcon icon={faChevronLeft} size={'2x'} />
            </button>
        );

        const CustomRightArrow = () => (
            <button className="bg-light border-0 text-primary" onClick={handleNextClick}>
                <FontAwesomeIcon icon={faChevronRight} size={'2x'} />
            </button>
        );

        return (
            <Row className={isMobile ? "mt-5" : "mt-2"}>
                {/* Left Arrow */}
                {helloRampStatus!=3 && (<Col style={{ marginRight: isMobile ? "0px" : "-10px" }} md={1} className="d-none d-md-flex flex-row-reverse align-items-center fs-10">
                    <CustomLeftArrow />
                </Col>)}

                {/* Carousel */}
                <Col col={12} md={8} lg={10} className="ms-0 text-center">
                    <Carousel
                        partialVisible={carImagesArray.length <= 3 ? false : true}
                        swipeable={true}
                        draggable={true}
                        responsive={responsive}
                        ssr={true}
                        infinite={carImagesArray.length >= 3}
                        arrows={false}
                        autoPlay={isMobile}
                        autoPlaySpeed={4000}
                        keyBoardControl={true}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        itemClass="carousel-item-padding-40-px"
                        ref={carouselRef}
                        showDots={isMobile}
                        renderDotsOutside={false}
                    >
                        {carImagesArray.map((data: any, index: any) => {
                            const imageUrl = `${process.env.REACT_APP_CLOUDFRONT_URL}${data.imageUrl}`;
                            const isSelected = selectedImage === imageUrl;

                            return (
                                <div
                                    key={data.id}
                                    onClick={() => handleImageClick(imageUrl)}
                                    style={{
                                        cursor: "pointer",
                                        border: isSelected ? "3px solid black" : "none",
                                        // borderRadius: "5px",
                                        padding: isSelected ? "2px" : "0",
                                        transition: isSelected ? "" : "transform 0.3s ease, box-shadow 0.3s ease",
                                        transform: isSelected ? "" : "perspective(1000px) rotateY(0deg) scale(1)",
                                        willChange: "transform, opacity"
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.transform = isSelected ? "" : "perspective(1000px) rotateY(5deg) scale(1.05)";
                                        e.currentTarget.style.boxShadow = isSelected ? "" : "0 10px 20px rgba(0, 0, 0, 0.3)";
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.transform = isSelected ? "" : "perspective(1000px) rotateY(0deg) scale(1)";
                                        e.currentTarget.style.boxShadow = isSelected ? "" : "none";
                                    }}
                                    className="single-item-carousel-detail cursor-pointer mb-5"
                                >
                                    {detailPage && index === 0 && id === "a8784040-8eb2-11ee-ac0e-a3802d150afb" ? (
                                        <div style={{ position: "relative", display: "inline-block" }} onClick={handle360View}>
                                            <Image src={imageUrl} className={isMobile ? "detail-carousel-img-mobile" : ""} />
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform: "translate(-50%, -50%)"
                                                }}
                                            >
                                                <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/360-badge.png`} className="w-50 h-100" />
                                            </div>
                                        </div>
                                    ) : (
                                        <Image src={imageUrl} className={isMobile ? "detail-carousel-img-mobile" : ""} />
                                    )}
                                </div>
                            );
                        })}
                    </Carousel>
                </Col>

                {/* Right Arrow */}
                {helloRampStatus!=3 && (<Col md={1} className="d-none d-md-flex align-items-center fs-10">
                    <CustomRightArrow />
                </Col>)}
            </Row>
        );
    };

    export default MultiCarousel;