import { useLocation } from "react-router";
import { encryptLocalStorage } from "./storage";
import { faCarBurst, faCarOn, faCarSide, faGauge, faGear, faGears,faWrench } from "@fortawesome/free-solid-svg-icons";

export const roles = {
    SUPER_ADMIN: '387cfdc1-3bf8-11ee-9e22-0242ac120003',
    BRANCH_ADMIN: '387fb53e-3bf8-11ee-9e22-0242ac120003',
    EVALUATOR: '387fb741-3bf8-11ee-9e22-0242ac120003',
    SALES_EXECUTIVE: '387fb85d-3bf8-11ee-9e22-0242ac120003',
    CUSTOMER: '30471e64-3cd7-11ee-aed0-0242ac120003'
}


export const VehicleSaleStatus = {
    VEHICLE_BOOKING_CANCEL_STATUS: 0,
    VEHICLE_UNPUBLISH_STATUS: 5,
    VEHICLE_BUY_STATUS: 9,
    VEHICLE_SELL_STATUS: 10,
    VEHICLE_PRE_BOOKED_STATUS: 15,
    VEHICLE_BOOKED_STATUS: 20,
    VEHICLE_SOLD_STATUS: 30,
    VEHICLE_PENDING_USER_INFO: 35,
}

export const BOOKING_CANCEL_STATUS = 40;
export const BOOKING_COMPLETED_STATUS = 50;

export const VehicleSaleStatusLabel: any = {
    5: 'Unpublished',
    9: 'Buy',
    10: 'On Process',
    // 10: 'Sell',
    20: 'Booked',
    30: 'Sold',
    35: 'Sell Application Processing',
}

export const CmsTypes = {
    PAGE_BLOCK: 1,
    TEXT_BLOCK: 2
}

export const SALE_PICKUP_HOME = 1
export const SALE_PICKUP_STORE = 2



export const getOriginalUrlFromThumb = (imageUrl: string): string => {
    const fileName = imageUrl.split('/').pop();
    const extension = fileName?.split('.').pop();
    if (!fileName || !extension) {
        return imageUrl;
    }
    let originalFileName = fileName.replace('_thumb', '');
    let newImageUrl = imageUrl.replace(fileName, `${originalFileName}`);
    newImageUrl = newImageUrl.replace('/thumbnail/', '/original/');
    return newImageUrl;
};

// export const handleAddToCompare = (id: string, slugCarName: string, navigate: any) => {
//     const compareString = encryptLocalStorage.getItem("compareCar");
//     const compareCar: { id: string, name: string }[] = compareString ? compareString : [];
//     if (compareCar.length == 0) {
//         compareCar.push({ id, name: slugCarName })
//         navigate(`/compare-car/${compareCar[0].name}/${compareCar[0].id}`);
//     } else if (compareCar.length == 1 && compareCar[0]) {
//         compareCar.push({ id, name: slugCarName })
//         navigate(`/compare-car/${compareCar[0].name}/${compareCar[1].name}/${compareCar[0].id}/${compareCar[1].id}`)
//     } else {
//         // On removing firstCar, value of first element in compareCar array becomes 'undefined', 
//         // so assign the new selected car to compare on first index of array
//         const index = compareCar[0] ? 1 : 0
//         compareCar[index] = { id, name: slugCarName }
//         if (compareCar.length == 1 || (compareCar[0] && !compareCar[1])) {
//             navigate(`/compare-car/${compareCar[0].name}/${compareCar[0].id}`)
//         } else {
//             navigate(`/compare-car/${compareCar[0].name}/${compareCar[1].name}/${compareCar[0].id}/${compareCar[1].id}`)
//         }
//     }
//     encryptLocalStorage.setItem("compareCar", compareCar);
// }

export const handleAddToCompare = (id: string, slugCarName: string, navigate: any, position?: string, isMobile?: boolean) => {
    const compareString = encryptLocalStorage.getItem("compareCar");
    const compareCar: { id: string, name: string }[] = compareString ? compareString : [];
    const carLimit = isMobile ? 2 : 4;

    const positionIndex: any = !isMobile ? {
        first: 0, second: 1, third: 2, fourth: 3
    } : {
        first: 0, second: 1
    }

    if (position) {
        const index = positionIndex[position]
        compareCar[index] = { id, name: slugCarName };
    } else {
        compareCar.push({ id, name: slugCarName });
    }

    if (compareCar.length <= carLimit) {
        encryptLocalStorage.setItem("compareCar", JSON.stringify(compareCar.filter(car => car))); // Filter out undefined values
    }

    // Navigate based on the number of cars in the comparison
    if (isMobile) {
        switch (compareCar.length) {
            case 1:
                navigate(`/comparison?firstCar=${compareCar[0].id}`, { replace: true }); // replace will remove state after navigation
                break;
            case 2:
                navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}`, { replace: true });
                break;
            default:
                navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}`, { replace: true, state: { aboveLimit: true } });
                break;
        }
    }
    else {
        switch (compareCar.length) {
            case 1:
                navigate(`/comparison?firstCar=${compareCar[0].id}`, { replace: true, state: { isLimit: 1 } }); // replace will remove state after navigation
                break;
            case 2:
                navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}`, { replace: true, state: { isLimit: 2 } });
                break;
            case 3:
                navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}&thirdCar=${compareCar[2].id}`, { replace: true, state: { isLimit: 3 } });
                break;
            case 4:
                navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}&thirdCar=${compareCar[2].id}&fourthCar=${compareCar[3].id}`, { replace: true, state: { isLimit: 4 } });
                break;
            default:
                navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}&thirdCar=${compareCar[2].id}&fourthCar=${compareCar[3].id}`, { replace: true, state: { aboveLimit: true, isLimit: 5 } });
                break;
        }
    }
    


}

export const directToComparePage = (navigate: any) => {
    const compareCar = encryptLocalStorage.getItem("compareCar") || []

    // Navigate based on the number of cars in the comparison
    switch (compareCar.length) {
        case 0:
            navigate(`/comparison`, { replace: true }); // replace will remove state after navigation
            break;
        case 1:
            navigate(`/comparison?firstCar=${compareCar[0].id}`, { replace: true }); // replace will remove state after navigation
            break;
        case 2:
            navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}`, { replace: true });
            break;
        case 3:
            navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}&thirdCar=${compareCar[2].id}`, { replace: true });
            break;
        case 4:
            navigate(`/comparison?firstCar=${compareCar[0].id}&secondCar=${compareCar[1].id}&thirdCar=${compareCar[2].id}&fourthCar=${compareCar[3].id}`, { replace: true });
            break;
        default:
            break;
    }
}


export const convertStringToSlug = (name: string) => {
    return encodeURIComponent(name.toLowerCase().replace(/\s+/g, '-'))
}

export const calculateEmi = (price: number) => {
    const interestRate = 12.5;
    const tenure = 60 // 5years 5*12
    const interest = (interestRate / 12) / 100;
    const emi = ((price * interest * (1 + interest) ** tenure) / (((1 + interest) ** tenure) - 1));
    return Math.round(emi)
}

export const handleRemoveFromCompare = (carDetails: any, navigate: any) => {
    console.log(carDetails)
    if (carDetails[0] == null && carDetails[1] == null) {
        navigate(`/buy-used-car`)
    } else if (carDetails[0]) {
        navigate(`/compare-car/${carDetails[0].name}/0/${carDetails[0].id}/0`)
    } else {
        navigate(`/compare-car/0/${carDetails[1].name}/0/${carDetails[1].id}`)
    }
}

export const parsePriceToFloat = (price: number, limit: number, val: number) => {
    let str = (price / limit).toString();
    str = str.slice(0, (str.indexOf(".")) + val + 1);
    return str;
}

export const ordinalSuffixForOwner = (i: number) => {
    let j = i % 10
    let k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export const trimContent = (content: string, limit: number) => {
    return content.length > limit ? `${content.substring(0, limit)}...` : content;
}

export const formatKm = (km: number) => {
    return (km / 1000).toFixed(2);
};

export const downloadFileFromApi = (fileData: any, fileName: string) => {

    // create file link in browser's memory
    const href = URL.createObjectURL(fileData);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

export const setSelectedLocation = (locationList: string[]) => {
    encryptLocalStorage.setItem("selectedLocation", locationList);
    const searchCriteria = encryptLocalStorage.getItem("searchCriteria")
    if (searchCriteria) {
        searchCriteria.selectedLocation = locationList
        encryptLocalStorage.setItem('searchCriteria', searchCriteria);
    }
}

// export const formatBudgetRange = (budget:any) => {
//     const million = 10 ** 6;
//     const lakh = 10 ** 5;

//     if (budget[1] > million) {
//         if (budget[0] >= lakh && budget[0] < million) {
//             return `${Math.round(budget[0] / lakh)} to 10+ Lakhs`; 
//         }
//         return '10+ Lakhs'; 
//     }

//     if (budget[0] < lakh && budget[1] < lakh) {
//         if (budget[0] < 100000) {
//             if (budget[0] == 0){
//                 return `0 to ${Math.round(budget[1] / 1000)}k`;
//             }
//             else {
//                 return `${Math.round(budget[0] / 1000)}k to ${Math.round(budget[1] / 1000)}k`;
//             }
//         }
//         return `${Math.round(budget[0] / 1000) / 100} to ${Math.round(budget[1] / 1000) / 100} Lakhs`;
//     } else if (budget[0] < lakh && budget[1] > lakh) {
//         return `${Math.round(budget[0] / 1000)}k  to ${Math.round(budget[1] / 1000) / 100} Lakhs`;
//     }
//     return `${Math.round(budget[0] / lakh)} to ${Math.round(budget[1] / lakh)} Lakhs`;
// }

export const formatBudgetRange = (budget: any) => {
    const million = 10 ** 6;
    const lakh = 10 ** 5;
    const thousand = 10 ** 3;

    const formatNumber = (budgetNumber: number) => {
        if (budgetNumber < 1000) {
            return `${budgetNumber}`;
        } else if (budgetNumber < lakh) {
            return `${Math.round(budgetNumber / thousand)}k`;
        } else if (budgetNumber === lakh) {
            return '1 Lakh';
        } else if (budgetNumber < million) {
            return `${Math.round(budgetNumber / lakh)} Lakh`;
        } else {
            return `${Math.round(budgetNumber / lakh)}+ Lakhs`;
        }
    }

    const start = formatNumber(budget[0]);
    const end = formatNumber(budget[1]);

    return `${start} to ${end}`;
}



export const truncateTextWithHtml = (content:any, length:any) => {
  const text = content.replace(/<[^>]+>/g, ''); // remove HTML tags
  if (text.length > length) {
    return text.substring(0, length) + '...';
  } else {
    return text;
  }
}

export const priceFormat = (price:string|number,locale:string,option?:any)=>{
    
    // Convert price to a number if it's a string
    const numericPrice = typeof price === 'string' ? parseInt(price) : price;
    
    // Ensure the numericPrice is a valid number
    if (isNaN(numericPrice)) {
        throw new Error('Invalid price value');
    }
    return numericPrice.toLocaleString(locale,option)
}

export const InspectionTypeOptions = [
    { id: 0, name: 'Highlights',icons:'' },
    { id: 1, name: 'Overview',icons:'' },
    { id: 2, name: 'Engine',icons:'engine.svg' },
    { id: 3, name: 'Drivetrain' ,icons:'drivetrain.svg'},
    { id: 4, name: 'Body Structure' ,icons:'structure.svg'},
    { id: 5, name: 'Exterior' ,icons:'Exterior.svg'},
    { id: 6, name: 'Interior' ,icons:'interior.svg'},
    { id: 7, name: 'Mechanical' ,icons:'Mechanical.svg' },
    { id: 8, name: 'Wheels & Tyres' ,icons:'wheelTyre.svg' }
]

export const FeatureTypeOptions = [
    //{ id: 1, name: 'Instrumentation' },
    { id: 2, name: 'Comfort and convenience' },
    { id: 3, name: 'Safety' },
    { id: 4, name: 'Entertainment & Communication' },
    { id: 5, name: 'Interior' },
    { id: 6, name: 'Exterior' },
    
]

export const SpecificationTypeOptions = [
    { id: 1, name: 'Dimensions' },
    { id: 2, name: 'Engine' },
    { id: 3, name: 'Transmission' },
    { id: 4, name: 'Suspension' },
    { id: 5, name: 'Steering' },
    { id: 6, name: 'Brake' },
    { id: 7, name: 'Performance' },
    { id: 8, name: 'Fuel' },
    { id: 9, name: 'Tyre' },
]

//Options for Specification,Feature Modal
export  const InputType = [
    { keyName: 'number', name: 'Number' },
    { keyName: 'text', name: 'Text' },
    { keyName: 'decimal', name: 'Decimal' },
    { keyName: 'dropdown', name: 'Dropdown' }
]

export const generateOrderNo = () => 'a' + 2 + Date.now() + Math.floor(Math.random() * (1000 - 0 + 1) + 1000)

export const TRANSACTION_PENDING = 1
export const TRANSACTION_SUCCESS = 2
export const TRANSACTION_FAILED = 3

export const insuranceType = [
    { id: 1, name: 'Comprehensive' },
    { id: 2, name: 'Third Party' }
]