
import { Col, Container, Nav, Row, Tab, Image } from "react-bootstrap";
import { useEffect, useState } from "react";
import HTTP from "../../../config/axios";
import TestDriveList from "../../../components/modules/Profile/TestDriveList";
import BookingList from "../../../components/modules/Profile/BookingList";
import SellOrderList from "../../../components/modules/Profile/SellOrderList";
import HelpSupport from "../../../components/modules/Profile/HelpSupport";
import Finance from "../../../components/modules/Profile/Finance";
import ReferAndEarn from "../../../components/modules/Profile/ReferAndEarn";
import PersonalInfo from "../../../components/modules/Profile/PersonalInfo";
import ProfileNav from "../../../components/modules/Profile/ProfileNav";
import { encryptLocalStorage } from "../../../config/utils/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import LogoutConfirmationModal from "../../../components/common/LogoutConfirmationModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/common/Breadcrumbs";

const Profile = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab')
  const [currentTab, setCurrentTab] = useState(tab || "sideTab_1")
  const [userInfo, setUserInfo] = useState({ firstName: "", lastName: "", email: "", phone: "", currentImagePath: "" })
  const [isMobile, setIsMobile] = useState(false)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    // Update the currentTab state when 'tab' changes
    setCurrentTab(tab || 'sideTab_1');
  }, [tab]);

  useEffect(() => {
    getUserData()
  }, [])

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767)
    window.addEventListener('resize', handleResize);
    setIsMobile(window.innerWidth <= 767);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getUserData = async () => {
    const response = await HTTP.get(`/api/fetch-profile`)
    const { data: { status, data, msg, error } } = response
    if (status == 1) {
      setUserInfo(data)
    }
  }


  const token = encryptLocalStorage.getItem("jwtToken");
  if (!token) {
    window.location.replace("/");
  }

  const handleLogout = () => {
    setShowLogoutConfirmation(true);
  };

  const confirmLogout = () => {
    localStorage.clear();
    setShowLogoutConfirmation(false);
    // window.location.replace("/sign-in/");
    navigate("/sign-in/")
  };

  const openSignInPage = () => {
    navigate("/sign-in/")
  }

  const cancelLogout = () => {
    setShowLogoutConfirmation(false);
  };

  const getCamelCaseUsername = () => {
    return userInfo.firstName.charAt(0).toUpperCase + userInfo.firstName.slice(1)
  }

  const breadCrumbList = [
    { title: "Home", path: "/", active: false, linkClass: "breadcrumb-text" },
    { title: "Profile", path: "/profile", active: true, linkClass: "text-primary fw-semibold" }
  ]


  return (
    <Container>
      {!isMobile ?
        (<Row className="pt-5 mt-5">
          <Tab.Container id="left-tabs-example"
            activeKey={currentTab}
            onSelect={(selectedTab: any) => setCurrentTab(selectedTab)}
            defaultActiveKey="sideTab_1" >
            <Row className="mb-5 profile mt-md-4 pe-0">
              {/* navpills */}
              <Breadcrumbs breadcrumbData={breadCrumbList} />
              <Col sm={12} md={4}>
                <div className="nav-pills-scroll">
                  <ProfileNav firstName={userInfo.firstName} profileImagePath={userInfo.currentImagePath} currentTab={currentTab} />
                </div>
              </Col>
              <Col md={8} className="ps-4">
                <Tab.Content>

                  {currentTab == "sideTab_0" && <PersonalInfo userInfo={userInfo} getUserData={getUserData} />}

                  {currentTab == "sideTab_1" && <TestDriveList />}

                  {currentTab == "sideTab_2" && <BookingList />}

                  {currentTab == "sideTab_3" && <SellOrderList />}

                  {currentTab == "sideTab_4" && <HelpSupport />}

                  {currentTab == "sideTab_5" && <Finance />}

                  {currentTab == "sideTab_6" && <ReferAndEarn />}

                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>) : (<>
          <Row className="mt-120">
            <Breadcrumbs breadcrumbData={breadCrumbList} />
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <Image
                  src={
                    userInfo.currentImagePath
                      ? `${process.env.REACT_APP_S3_BASE_URL}/${userInfo.currentImagePath}`
                      : `${process.env.REACT_APP_CLOUDFRONT_URL}/public/pro_pic.png`
                  }
                  className="rounded-circle"
                  height={"70px"}
                  width={"70px"}
                />
                <p className="align-self-center ps-3 mb-0 lh-base text-dark fw-semibold">
                  {" "}
                  Hello, <br /> {userInfo.firstName.toUpperCase()}
                </p>
              </div>
              <div className="d-flex mb-3">
                <div>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    className="text-primary fs-6 me-2"
                  />
                </div>
                <div className="me-auto" onClick={handleLogout}>Log Out</div>
              </div>
            </div>
          </Row>
          <Tab.Container id="left-tabs-example" activeKey={currentTab}
            onSelect={(selectedTab: any) => setCurrentTab(selectedTab)}
            defaultActiveKey="sideTab_1"
          >
    
              <div className="nav-pills-scroll ">
                <Nav
                  variant="pills"
                  className="flex-md-column pt-4 ps-0"
                >
                  <Nav.Item className="py-2">
                    <Nav.Link
                      eventKey="sideTab_0"
                      className=" is-description nav-link-mobile"
                    >
                      Profile
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="py-2">
                    <Nav.Link
                      eventKey="sideTab_1"
                      className=" is-description nav-link-mobile"
                    >
                      Test Drives
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="py-2">
                    <Nav.Link
                      eventKey="sideTab_2"
                      className=" is-description nav-link-mobile"
                    >
                      Bookings
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="py-2">
                    <Nav.Link
                      eventKey="sideTab_3"
                      className=" is-description nav-link-mobile"
                    >
                      Sell Orders
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="py-2">
                    <Nav.Link
                      eventKey="sideTab_4"
                      className=" is-description nav-link-mobile"
                    >
                      Help & Support
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="py-2">
                    <Nav.Link
                      eventKey="sideTab_5"
                      className=" is-description nav-link-mobile"
                    >
                      Finance
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="py-2">
                    <Nav.Link
                      eventKey="sideTab_6"
                      className=" is-description nav-link-mobile"
                    >
                      Refer and Earn
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
     
            <Col col={12}>
              <Tab.Content>
                {currentTab == "sideTab_0" && <PersonalInfo userInfo={userInfo} getUserData={getUserData} />}

                {currentTab == "sideTab_1" && <TestDriveList isMobile />}

                {currentTab == "sideTab_2" && <BookingList isMobile />}

                {currentTab == "sideTab_3" && <SellOrderList isMobile />}

                {currentTab == "sideTab_4" && <HelpSupport />}

                {currentTab == "sideTab_5" && <Finance />}

                {currentTab == "sideTab_6" && <ReferAndEarn isMobile />}
              </Tab.Content>
            </Col>
          </Tab.Container>
        </>)}
      <LogoutConfirmationModal
        show={showLogoutConfirmation}
        onClose={cancelLogout}
        onConfirm={confirmLogout}
      />
    </Container>
  );
}

export default Profile;
