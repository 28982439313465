import { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { Col, Container, Row, Form, Image, Modal ,Button} from "react-bootstrap";
import { Link } from "react-router-dom";

const BasicEmiCalculator: React.FC<any> = ({ price }) => {
    const [sliderLoanAmount, setSliderLoanAmount] = useState(parseInt(price) > 2000000 ? 2000000 : parseInt(price));
    const [sliderLoanDuration, setSliderLoanDuration] = useState(3);
    const [sliderInterestRate, setSliderInterestRate] = useState(12.5);
    const [calculateEmi, setCalculateEmi] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalInterest, setTotalInterest] = useState(0)
    const [showLoanBreakupModal, setShowLoanBreakupModal] = useState(false);


    const handleLoanAmountChange = (event: any) => {
        setSliderLoanAmount(parseInt(event.target.value));
    };

    const handleLoanDurationChange = (event: any) => {
        setSliderLoanDuration(event.target.value);
    };

    const handleInterestChange = (event: any) => {
        setSliderInterestRate(event.target.value);
    };

    const handleCalculateEmi = () => {
        const amount = sliderLoanAmount;
        const tenure = sliderLoanDuration * 12;
        const interest = sliderInterestRate / 12 / 100;
        const emi = ((amount * interest * (1 + interest) ** tenure) / (((1 + interest) ** tenure) - 1));
        const total = emi * tenure;
        setTotalAmount(total);
        setTotalInterest(total - amount);
        return Math.round(emi);
    };

    useEffect(() => {
        const emi = handleCalculateEmi();
        setCalculateEmi(emi)
    }, []);

    useEffect(() => {
        const emi = handleCalculateEmi();
        setCalculateEmi(emi)
    }, [sliderLoanAmount, sliderLoanDuration, sliderInterestRate]);

    const openLoanBreakupModal = () => {
        setShowLoanBreakupModal(true);
    };

    const closeLoanBreakupModal = () => {
        setShowLoanBreakupModal(false);
    };


    return (
        <Container className="px-0">
            <div className="mt-3">
                <h3 className="text-primary mb-0 is-testimonials mob-text-align-left">EMI Calculator</h3>
            </div>
            {/* Loan amount range selector */}
        <div className="border rounded p-3 mt-2 ">
                <div className="d-flex mb-3">
                <div className="me-auto text-dark">Loan Amount</div>
                <div className="text-primary">₹ {sliderLoanAmount.toLocaleString('en-IN')}</div>
            </div>
            <Form.Range
                min={50000}
                step={100}
                max={2000000}
                bsPrefix={'range-color'}
                value={sliderLoanAmount}
                onChange={handleLoanAmountChange}
                style={{ cursor: "pointer" }}
            />
            <div className="d-flex mb-3">
                <div className="me-auto">₹ 50,000</div>
                <div>₹ 20,00,000</div>
            </div>
            {/* Duration range selector */}
            <div className="d-flex mb-3">
                <div className="me-auto text-dark">Loan Duration</div>
                <div className="text-primary">{sliderLoanDuration} year</div>
            </div>
            <Form.Range
                min={1}
                step={1}
                max={7}
                bsPrefix={'range-color'}
                value={sliderLoanDuration}
                onChange={handleLoanDurationChange}
                style={{ cursor: "pointer" }}
            />
            <div className="d-flex mb-3">
                <div className="me-auto">1 year</div>
                <div>7 years</div>
            </div>
            {/* Interest range selector */}
            <div className="d-flex mb-3">
                <div className="me-auto text-dark">Interest rate</div>
                <div className="text-primary">{sliderInterestRate} %</div>
            </div>
            <Form.Range
                min={1}
                max={30}
                step={0.1}
                value={sliderInterestRate}
                onChange={handleInterestChange}
                bsPrefix={'range-color'}
                style={{ cursor: "pointer" }}
            />
            <div className="d-flex mb-3">
                <div className="me-auto">1 %</div>
                <div>30 %</div>
            </div>

            <Col >
                    <h3 className="text-primary is-DetailFeatures">₹ {calculateEmi.toLocaleString('en-IN')}/- <span className="fs-7">Emi for {sliderLoanDuration} years</span></h3>
                <hr />
                <Row>
                    <Col md={4} sm={4} xs={5}>
                        <p className="text-dark">Interest rate*</p>
                    </Col>
                    <Col md={8} sm={8} xs={7} style={{ cursor: "pointer" }}>
                        <div className="d-flex">
                            <div><Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/public/view_loan_breakup.svg`} className="me-2" width={'60%'} /></div>
                            <div>
                                <p className="text-primary mb-0" onClick={openLoanBreakupModal} > View loan breakup</p>
                            </div>
                        </div>

                    </Col>
                </Row>
                <p className="text-dark">{sliderInterestRate}%</p>
                <p >*Rate of interest can vary subject to credit profile. <br />Loan approval is at the sole
                    discretion of the finance partner.</p>
            </Col>
            <Modal
                show={showLoanBreakupModal}
                onHide={closeLoanBreakupModal}
                centered
                size="sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Loan Breakup</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Monthly EMI: ₹{calculateEmi.toLocaleString('en-IN')}</p>
                    <p>Total amount: ₹ {Math.round(totalAmount).toLocaleString('en-IN')}</p>
                    <p>Total interest: ₹ {Math.round(totalInterest).toLocaleString('en-IN')}</p>
                </Modal.Body>
            </Modal>

            <div className="d-flex justify-content-end">
       <Link to={`/finance`}>
                            <Button variant="primary"  className="mb-3 mt-2" title="Apply finance">
                Apply finance
                            </Button>
                        </Link>
      </div>
        </div>
        </Container >
    );
};

export default BasicEmiCalculator;
