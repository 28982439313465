import React, { useEffect, useState } from 'react';
import HTTP from '../../../config/axios';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPen, faTrash, faUndo, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from 'react-bootstrap';
import CustomModal from '../../common/CustomModal';
import SimpleToast from '../../common/Toast';
import InputGroupField from '../../form/InputGroupField';
import { useForm } from 'react-hook-form';
import ImageCellForDataTable from '../../common/ImageCellForDataTable';
import { useNavigate, createSearchParams } from 'react-router-dom';
import DropDownField from '../../form/DropDownField';
import { VehicleSaleStatus } from '../../../config/utils/helper';
import { downloadFileFromApi } from '../../../config/utils/helper';
import SimpleSpinner from '../../common/Spinner';
import Checkbox from '../../form/Checkbox';


const VehicleTable: React.FC<any> = ({ showModal, handleClose, show }) => {
    const [vehicleVehicleData, setVehicleVehicleData] = useState([])
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [perPage, setPerPage] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<number | null>(null);
    const [toast, setToast] = useState({ open: false, header: '', message: '', variant: '' })
    const [totalRows, setTotalRows] = useState(0);
    const [searchText, setSearchText] = useState('')
    const [sortDetails, setSortDetails] = useState({ sortColumn: '', sortDirection: '' })
    const [removedIds, setRemovedIds] = useState<any>([])
    const [openActivateModal, setOpenActivateModal] = useState(false)
    const { control, reset, watch } = useForm<Record<string, any>>({ defaultValues: { vehicleStatus: '', saleStatus: '', search: "" } });
    const navigate = useNavigate();
    const helloRampStatus = watch('helloRamp')

    //Custom Style for the data table
    const customStyles = {
        headCells: {
            style: {
                fontSize: "15px",
                fontWeight: "700"
            },
        },
    };

    const editVehicle = (data: any) => {
        let searchQuery = ""
        if (data) {
            searchQuery = createSearchParams({
                vehicle: data
            }).toString()
        }
        navigate({
            pathname: '/admin/vehicleEdit',
            search: searchQuery
        })
    }

    const addVehicle = () => {

        navigate('/admin/vehicleAdd')
    }

    const columns: any = [
        {
            name: 'Registration',
            selector: (row: { registration: any; }) => {
                return <span title={row.registration}> {row.registration}</span>
            },
            width: "12.5%"
        }, {
            name: <div>Name</div>,
            selector: (row: { makeName: string; modelName: string; variantName: string }) => {
                return (`${row.makeName} ${row.modelName} ${row.variantName}`)
            },

            wrap: true,
            sortable: true,
            keyName: 'makeName',
            width: "17%"
        }, {
            name: 'Price',
            selector: (row: { price: any; }) => row.price,
            grow: 0,
            sortable: true,
            keyName: 'price',
            width: "11.5%"
        }, {
            name: <div> Transmission & Fuel </div>,
            selector: (row: { transmissionName: any; fuelName: any }) => {
                return row.transmissionName ? <span title={row.transmissionName + ', ' + row.fuelName}>{row.transmissionName}, {row.fuelName}</span> : ''
            },
            // width:'140px',
            keyName: 'transmissionName',
            width: "12.5%"
        }, {
            name: 'Colour',
            selector: (row: { colourName: any; }) => row.colourName,
            width: "9%"

        }, {
            name: <div> Location </div>,
            selector: (row: { locationName: any; }) => row.locationName,
            sortable: true,
            keyName: 'locationName',
            width: "12.5%"
        }, {
            name: 'Image',
            cell: (row: { imageUrl: any; }) => row.imageUrl && <ImageCellForDataTable maxWidth="80px" value={row.imageUrl} />,
            center: true,
            width: "12.5%"
        },
        {
            name: 'Actions',
            width: "12.5%",
            cell: (row: any) => (
                <div>

                    {(row.status != 0) ?
                        <>
                            <Button variant="success " onClick={() => editVehicle(row.uuid)} size="sm" className="me-1">
                                <FontAwesomeIcon icon={faPen} />
                            </Button>
                            <Button onClick={() => handleDeleteClick(row.id)} size="sm" className="" >
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </> :
                        <Button variant="success " onClick={() => handleActivateClick(row.id)} size="sm" className="" >
                            <FontAwesomeIcon icon={faUndo} />
                        </Button>
                    }

                </div>
            ),
            center: true,
        }
    ];

    interface IgetAllVehicle {
        page: number, limit: number,
        searchText?: string,
        sortColumn?: { sortColumn: string, sortDirection: string },
    }


    const vehicleStatusType = watch('vehicleStatus')
    const saleStatusType = watch('saleStatus')

    //Fetching the intial data for the data table
    useEffect(() => {
        const getAllVehicle = async () => {
            // setIsLoading(true);
            const params = {
                searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                status: vehicleStatusType != 'all' ? vehicleStatusType : '',
                saleStatus: saleStatusType != 'all' ? saleStatusType : '',
                helloRampStatus: helloRampStatus
            };
            const response = await HTTP.get(`/api/admin/vehicle/fetch-all/${currentPage}/${perPage}`, { params });
            const { data: { status, data, msg, error, totalRecords } } = response;
            setVehicleVehicleData(data.rows);
            setTotalRows(data.count.length)
            // setIsLoading(false)
        }
        getAllVehicle()
    }, [show, currentPage, perPage, searchText, sortDetails, removedIds, vehicleStatusType, saleStatusType, helloRampStatus]);


    const currentSearchText = watch('search');
    useEffect(() => {
        setSearchText(currentSearchText)
    }, [currentSearchText])

    const getAllVehicleExcel = async () => {
        try {
            setIsLoading(true);
            const params = {
                searchText: searchText, sortDetails: JSON.stringify(sortDetails),
                status: vehicleStatusType != 'all' ? vehicleStatusType : '',
                saleStatus: saleStatusType != 'all' ? saleStatusType : '',
                helloRampStatus: helloRampStatus
            };
            const response = await HTTP.get(`/api/admin/vehicle/fetch-all/excel`, { params, responseType: 'blob', });
            const filename = response.headers['content-disposition'].split('filename="')[1].split('"')[0];
            downloadFileFromApi(response.data, filename)
            setIsLoading(false);
        }
        catch (error) {
            console.log("Error in enquery download api", error);
            setIsLoading(false);
        }

    }



    //Handling the page change
    const handlePageChange = (page: any) => {
        setCurrentPage(page)
    }

    //handling the no.of rows changing
    const handlePerRowsChange = async (newPerPage: number, page: number) => {
        setPerPage(newPerPage)
    };

    //handling Delete of vehicle
    const handleDeleteClick = (id: number) => {
        setSelectedRecord(id);
        setIsOpenModal(true)
    }

    const handleActivateClick = (id: number) => {
        setSelectedRecord(id);
        setOpenActivateModal(true)
    }

    const handleCancelClick = () => {
        setSelectedRecord(null);
        setIsOpenModal(false)
        setOpenActivateModal(false)
    }

    const handleDelete = async () => {
        setIsOpenModal(false)
        const response = await HTTP.patch(`/api/admin/vehicle/remove/${selectedRecord}`);
        const { data: { status, data, totalCount, message, error } } = response
        if (status == 1) {
            setRemovedIds([...removedIds, selectedRecord])
            setToast({ open: true, header: 'Success', message: 'Vehicle  deleted successfully', variant: 'success' })
        } else {
            setToast({ open: true, header: 'Failed', message: message || 'Something went wrong', variant: 'danger' })
        }
    }

    const handleRecover = async () => {
        try {
            setOpenActivateModal(false)
            setIsLoading(true);
            const response = await HTTP.patch(`/api/admin/vehicle/recover/${selectedRecord}`);
            const { data: { status, data, totalCount, msg, error } } = response
            if (status == 1) {
                setRemovedIds([...removedIds, selectedRecord])
                setIsLoading(false);
                setToast({ open: true, header: 'Success', message: 'Vehicle recovered successfully', variant: 'success' })
            } else {
                setIsLoading(false);
                setToast({ open: true, header: 'Failed', message: 'Something went wrong', variant: 'danger' })

            }
        }
        catch (err) {
            console.log('Something went wrong')
        }
        finally {
            setIsLoading(false);
        }
    }

    //Handling the sort
    const handleSort = (column: any, sortDirection: any) => {
        const columnDetails = { sortColumn: column.keyName, sortDirection: sortDirection }
        setSortDetails(columnDetails)
    }

    //Vehicle Status
    const vehicleStatusTypeArray = [
        { keyName: 'all', name: "All" },
        { keyName: 1, name: "Active" },
        { keyName: 0, name: "Archived" },
    ]

    //Vehicle Sale Status
    const SaleStatusTypeArray = [
        { keyName: 'all', name: "All" },
        { keyName: VehicleSaleStatus.VEHICLE_SELL_STATUS, name: "Sell" },
        { keyName: VehicleSaleStatus.VEHICLE_PRE_BOOKED_STATUS, name: "Pre-Booked" },
        { keyName: VehicleSaleStatus.VEHICLE_BOOKED_STATUS, name: "Booked" },
        { keyName: VehicleSaleStatus.VEHICLE_SOLD_STATUS, name: "Sold" },
        { keyName: VehicleSaleStatus.VEHICLE_BUY_STATUS, name: "Purchase" },
        { keyName: VehicleSaleStatus.VEHICLE_UNPUBLISH_STATUS, name: "Unpublished" },
    ]

    return (<>

        <Row className='mb-1'>
            <div className="d-flex">
                <div className="p-2 flex-grow-1"><h2 className="mb-0 text-gray">Vehicles</h2></div>
                <div className="p-2 mt-2" style={{ cursor: "pointer" }}>
                    <Checkbox name="helloRamp" control={control} label="Show 360 Vehicles"
                        classList="" /></div>
                <div className="p-2 mt-2 text-success" title="Download Excel Report">
                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={getAllVehicleExcel} style={{ cursor: 'pointer' }} />
                </div>
                <div className="p-2">
                    <DropDownField label="" name="vehicleStatus" options={vehicleStatusTypeArray}
                        control={control} placeholder="Vehicle Status" isValueKeyname={true} />
                </div>
                <div className="p-2">
                    <DropDownField label="" name="saleStatus" options={SaleStatusTypeArray}
                        control={control} placeholder="Sale Status" isValueKeyname={true} />
                </div>
                <div className="p-2">
                    <Button onClick={addVehicle} variant="success"> Add Vehicle </Button>
                </div>
                <div className="p-2">
                    <InputGroupField name="search" placeholder="Search" label="" control={control} addOn={faSearch} />
                </div>
            </div>
        </Row>

        <DataTable
            columns={columns}
            data={vehicleVehicleData}
            // progressPending={isLoading}
            striped={true}
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={true}
            customStyles={customStyles}
        />

        <CustomModal isOpen={isOpenModal} title="Delete vehicle type"
            component={<p>Are you sure you want to delete?</p>}
            closeModal={setIsOpenModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleDelete}>
                    Confirm
                </Button></>} />

        <CustomModal isOpen={openActivateModal} title="Recover Vehicle"
            component={<p>Are you sure you want to recover?</p>}
            closeModal={setOpenActivateModal} buttonList={<>
                <Button variant="secondary" onClick={handleCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleRecover}>
                    Confirm
                </Button></>} />

        <SimpleToast show={toast.open} header={toast.header}
            message={toast.message} variant={toast.variant}
            onClose={() => setToast({ ...toast, open: false })} />

        <SimpleSpinner show={isLoading} />

    </>)
}

export default VehicleTable;