import React, { SetStateAction, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Controller, Control } from "react-hook-form";
import Select, { ActionMeta, OptionsOrGroups } from 'react-select';
import makeAnimated from 'react-select/animated';

interface IDropDownField {
    name: string;
    label?: string;
    options?:  { label: string; value: number | string }[];
    control: Control;
    placeholder?: string;
    classList?: string;
    rule?: any;
    clearErrors?: Function;
    errText?: string;
    isValueKeyname?: boolean;
    defaultValues?: { label: string; value: number | string }[];
    selectedValues?: { label: string; value: number | string }[];
    setValues?: any
}

const MultiSelectDropdown: React.FC<IDropDownField> = ({
    name,
    label,
    options,
    control,
    placeholder = '',
    classList,
    rule,
    clearErrors,
    errText = '',
    isValueKeyname,
    defaultValues= [],
    selectedValues = [],
    setValues
}) => {



    const handleSelectChange = (event: any, onChange: Function, actionMeta: any) => {
        if(actionMeta.action == 'clear'){
            setValues([])
        }
        onChange(event)
    };


    const handleError = () => {
        if (clearErrors) clearErrors();
    };

    if (rule?.required && typeof rule.required === "function") {
        rule.required = rule.required(label);
    }

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>, onChange: Function) => {
        const selectedValue = event.target.value;

        if (onChange) {
            onChange(selectedValue);
        }
        handleError();
    };

    // const OptionList = () => {
    //     interface OptionObject {
    //         id?: number;
    //         keyName?: string | number;
    //         name: string;
    //     }

    //     type Option = OptionObject | string | number;

    //     return <>
    //         {options && options.length &&
    //             options.map((option: Option) => {
    //                 if (typeof option === 'object') {
    //                     // Object case: Option is an OptionObject
    //                     const optionValue = isValueKeyname ? option.keyName : option.id
    //                     return (
    //                         <option key={optionValue} value={optionValue}>
    //                             {option.name}
    //                         </option>
    //                     );
    //                 } else {
    //                     // String or number case: Option is a string or number
    //                     return (
    //                         <option key={option} value={option}>
    //                             {option}
    //                         </option>
    //                     );
    //                 }
    //             })
    //         }
    //     </>;
    // };

    
     const animatedComponents = makeAnimated();



    return (
        <div className={classList}>
            <Controller
                name={name}
                control={control}
                rules={rule}
                render={({ field: { onChange, onBlur, value, name, ref }, fieldState: { error } }) => (
                    <>
                        {label &&
                            <Form.Label htmlFor={`${name}-id`} className="text-dark fw-semibold fs-6">
                                {label}{rule?.required && <span className="text-danger fw-bold">*</span>}
                            </Form.Label>}
                        

                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            id={`${name}-id`}
                            defaultValue={defaultValues}
                            options={options}
                            className="basic-multi-select z-3"
                            classNamePrefix="react-select"
                            onChange={(e, action) => handleSelectChange(e, onChange, action)}
                            value={selectedValues?selectedValues.filter(v => v.label):null}                          
                            placeholder={placeholder}
                            blurInputOnSelect={false}
                            hideSelectedOptions
                            components={animatedComponents}
                        />


                             
                        {error?.message &&
                            <Form.Text className="text-danger">
                                {/* Show custom error if present and required message is not triggeres */}
                                {error.type != 'required' && errText ? errText : error.message}
                            </Form.Text>}
                    </>
                )}
            />
        </div>
    );
};

export default MultiSelectDropdown;

